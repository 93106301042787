import React from 'react';
import PropTypes from 'prop-types'
import { LocaleContext } from '../components/layout/layout';
import ContactSection from '../components/Sections/Contact';
import { Container, Section } from '../styles/common/Layout';
import { H1, A, H1Content } from '../styles/common/Typo';
import { SEO } from '../components';

const UsesPage = ({ data, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale];

  return (
    <>
      <SEO
        title={`${i18n.pageUses} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale} />
      <Section className="section__intro">
        <Container>
          <H1Content>Marek uses...</H1Content>
          <p className="mb-8 leading-relaxed">Sometimes people asks about the specifics of software or hardware I use.<br />
          So here we go! I try to answer and list as much as possible. It changes fairly often.<br />
          If you have any questions, hit me up at <A href="mailto:mail@marek-maras.de"><span>mail@marek-maras.de</span></A></p>
        </Container>
      </Section>
      <Section className="section__editor" Color="White">
        <Container>
          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/5">
              <H1>Editor + Terminal</H1>
            </div>
            <div className="w-full md:w-3/5">
              <ul className="list-square list-inside">
                <li>I use <A title="VS Code" href="https://code.visualstudio.com/" rel="noreferrer"><span>Visual Studio Code</span> </A> as my Editor</li>
                <li>Theme I use is <A href="https://marketplace.visualstudio.com/items?itemName=wesbos.theme-cobalt2" rel="noreferrer"><span>Cobalt2</span></A> by <A title="Wesbos.com" href="https://wesbos.com" rel="noreferrer"><span>Wesbos</span></A></li>
                <li>Currently my main browser is Google Chrome. For Dev I also use Microsoft Edge and Firefox.</li>
                <li>Terminal to go is PowerShell, as I work on Windows 10 Pro</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
      <Section className="section__desktop">
        <Container>
          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/5">
              <H1>Desktop Apps</H1>
            </div>
            <div className="w-full md:w-3/5">
              <ul className="list-square list-inside">
                <li>Backup and file storage -&gt; Dropbox</li>
                <li>Notion for Project Management and ToDo lists</li>
                <li>Adobe Photoshop 2020 for graphical edits</li>
                <li>Spotify AND Apple Music for great music</li>
                <li>Discord for communications</li>
                <li>Steam for gaming! ;)</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
      <Section className="section__hardware" Color="White">
        <Container>
          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/5">
              <H1>PC Hardware</H1>
            </div>
            <div className="w-full md:w-3/5">
              <ul className="list-square list-inside">
                <li>2x 24 Inch 144hz FullHD Screens by ViewSonic</li>
                <li>AMD Ryzen 5 3600 CPU PC with 32 GB DDR4 3600ghz RAM, Nvidia 1060 GPU and 2TB Samsung 970 Evo Plus as SSD</li>
                <li>2x external 1TB drives for local backup</li>
                <li>Logitech C22 HD 1080p Webcam for Discord, Zoom and Recording</li>
                <li>Steelseries Arctis 7 Wireless Headset</li>
                <li>Apex 500 as my keyboard and fnatic clutch 2 as my mouse. Comfy!</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
      <Section className="section__hardware" >
        <Container>
          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/5">
              <H1>Miscellaneous</H1>
            </div>
            <div className="w-full md:w-3/5">
              <ul className="list-square list-inside">
                <li>iPhone XS 128GB</li>
                <li>Airpods Pro</li>
                <li>iPad Pro 11'</li>
                <li>Siemens EQ.500 Coffee Maker (Lifesaver!)</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
      <ContactSection />
    </ >
  );
};
export default UsesPage;
